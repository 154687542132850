import React, { useState, useEffect } from "react"
import SlideToggle from "react-slide-toggle"
import InputRange from "react-input-range"
import { connect } from 'react-redux'
import "react-input-range/lib/css/index.css"
import { Modal, Button } from "react-bootstrap"
import { toggleFilter } from "../../../state/filter"
import RenderHomeType from "../../Filter/RenderHomeType"
import { getUser } from "../../../services/auth"

const MobileFilterModal = ({ show, handleClose, activeFilters, dispatch }) => {
  const user = getUser();
  const Location = user.Location;
  const url = typeof window !== 'undefined' ? window.location.pathname : '';
  const onChange = value => {
  }

  let last_url_character = url.slice(-1);
  var checkURL = last_url_character == '/' ? url.substring(0, url.length - 1) : url;
  const segment = checkURL.substring(checkURL.lastIndexOf('/') + 1);
  // default filters 
  var filters = [];
  var filterList = {
    '': [
      'Style', 'HomeType', 'Room', 'PriceSlider'/*, 'Designer' */
    ],
    'explore': [
      'Style', 'HomeType', 'Room', 'PriceSlider'/*, 'Designer' */
    ],
    'home-designs': [
      /*'HomeType', */'Storeys', 'Bed', 'Bath', 'Garage', 'LivingSpaces', 'Room', 'PriceSlider',/* 'LotWidth'*/
    ],
    'facades': [
      'Storeys'/*'Style', 'HomeType', 'Storeys', 'PriceSlider'*/
    ],
    'land': [
      'AddressSearch', 'LandArea', 'LandSize', 'PriceSlider'
    ],
    'style': [
      'Style', 'PriceSlider'/*, 'Designer' */
    ],
    'finishes-fixtures': [
      'SubCategory',/* 'Style', */'Colour', 'Category',  /*'Finish', 'PriceSlider', 'Brand'*/
    ],
    'furniture': [
      /*'SubCategory', 'Style', 'Colour', 'PriceSlider', 'Brand'*/
    ],
    'cabinetry': [
      /*'SubCategory', 'Style', 'Room', 'Colour', 'PriceSlider'*/
    ]
  };

  if (typeof filterList[segment] !== 'undefined') {
    filters = filterList[segment];
  }

  return (
    <Modal
      className="mSidebar pl-0"
      show={show}
      onHide={handleClose}
      animation={true}
    >
      <div className="mSidebar-inner">
        <Modal.Header closeButton={true}></Modal.Header>
        <div className="m-2">
          <div className="filter-modal-container">
            <div className="d-flex justify-content-between">
              <div
                className="reset-filter cursor-pointer"
                onClick={() => {
                  window.location.reload()
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="25.029" height="25.031" viewBox="0 0 25.029 25.031">
                  <path data-name="Path 1055" d="M2.615.752l.012 5.39 5.37.012m-5.313-.102a11.767 11.767 0 11-1.932 6.465" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                </svg>
                Reset Filters
              </div>
            </div>

            <RenderHomeType isMobile={true} list={filters} activeFilters={activeFilters} onSelectItem={filter => {
              dispatch(toggleFilter(filter))
            }}/>
          </div>
        </div>
      </div>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  activeFilters: state.filter.active
})

export default connect(mapStateToProps, null)(MobileFilterModal)
