import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import StyleFilter from './StyleFilter'

const RenderHomeType = data => {
  const allAttributes = useStaticQuery(graphql`
  {
      silverStripeDataObject(className: {eq: "Undigital__Objects__GlobalConfig"}) {
          UndigitalGlobalConfig {
              hidePricingGlobally
              maxBathrooms
              maxBedrooms
              maxLivingSpaces
              maxGarages
          }
        }
      allSilverStripeDataObject(filter: {className: { in: [
          "Undigital__Objects__ProductStyle", 
          "Undigital__Objects__HomeType", 
          "Undigital__Objects__RoomAmenity", 
          "Undigital__Objects__Designer", 
          "Undigital__Objects__Storeys", 
          "Undigital__Objects__Brand",
          "Undigital__Objects__ProductCategory", 
          "Undigital__Objects__Finish", 
          "Undigital__Objects__Colour"]}}) {
            nodes {
              UndigitalProductStyle {
                hideFromFilter
                name
                objectID
              }
              UndigitalHomeType {
                hideFromFilter
                name
                objectID
              }
              UndigitalRoomAmenity {
                hideFromFilter
                name                
                objectID
              }
              UndigitalDesigner {
                hideFromFilter
                name
                objectID
              }
              UndigitalStoreys {
                hideFromFilter
                name
                objectID
              }
              UndigitalBrand {
                hideFromFilter
                name
                objectID
              }
              UndigitalProductCategory {
                hideFromFilter
                name
                objectID
              }
              UndigitalFinish {
                hideFromFilter
                name
                objectID
              }
              UndigitalColour {
                hideFromFilter
                name
                objectID
              }
            }
      }
  }
`);
  var ProductStyles = [];
  var HomeTypes = [];
  var Rooms = [];
  var Designers = [];
  var Storeys = [];
  var Brands = [];
  var Categories = [];
  var Finishes = [];
  var Colours = [];
  var i = 0;
  var Attributes = allAttributes.allSilverStripeDataObject.nodes;
  var hidePricingGlobally = 0;
  for (; Attributes[i];) {
    let item = Attributes[i];
    if (item.UndigitalBrand !== null && Brands[item.UndigitalBrand.objectID] !== 'undefined') {
        if(item.UndigitalBrand.hideFromFilter != 1) {
            Brands[item.UndigitalBrand.objectID] = item.UndigitalBrand.name;
        }
    }
    if (item.UndigitalDesigner !== null && Designers[item.UndigitalDesigner.objectID] !== 'undefined') {
        if(item.UndigitalDesigner.hideFromFilter != 1) {
            Designers[item.UndigitalDesigner.objectID] = item.UndigitalDesigner.name;
        }
    }
    if (item.UndigitalHomeType !== null && HomeTypes[item.UndigitalHomeType.objectID] !== 'undefined') {
        if(item.UndigitalHomeType.hideFromFilter != 1) {
            HomeTypes[item.UndigitalHomeType.objectID] = item.UndigitalHomeType.name;
        }
    }
    if (item.UndigitalProductStyle !== null && ProductStyles[item.UndigitalProductStyle.objectID] !== 'undefined') {
        if(item.UndigitalProductStyle.hideFromFilter != 1) {
            ProductStyles[item.UndigitalProductStyle.objectID] = item.UndigitalProductStyle.name;
        }
    }
    if (item.UndigitalRoomAmenity !== null && Rooms[item.UndigitalRoomAmenity.objectID] !== 'undefined') {
        if(item.UndigitalRoomAmenity.hideFromFilter != 1) {
            Rooms[item.UndigitalRoomAmenity.objectID] = item.UndigitalRoomAmenity.name;
        }
    }
    if (item.UndigitalStoreys !== null && Storeys[item.UndigitalStoreys.objectID] !== 'undefined') {
        if(item.UndigitalStoreys.hideFromFilter != 1) {
            Storeys[item.UndigitalStoreys.objectID] = item.UndigitalStoreys.name;
        }
    }
    if (item.UndigitalProductCategory !== null && Categories[item.UndigitalProductCategory.objectID] !== 'undefined') {
        if(item.UndigitalProductCategory.hideFromFilter != 1) {
            Categories[item.UndigitalProductCategory.objectID] = item.UndigitalProductCategory.name;
        }
    }
    if (item.UndigitalFinish !== null && Finishes[item.UndigitalFinish.objectID] !== 'undefined') {
        if(item.UndigitalFinish.hideFromFilter != 1) {
            Finishes[item.UndigitalFinish.objectID] = item.UndigitalFinish.name;
        }
    }
    if (item.UndigitalColour !== null && Colours[item.UndigitalColour.objectID] !== 'undefined') {
        if(item.UndigitalColour.hideFromFilter != 1) {
            Colours[item.UndigitalColour.objectID] = item.UndigitalColour.name;
        }
    }
    i++;
  }
  var allFilters = [];
  allFilters['Style'] = ProductStyles;
  allFilters['Brand'] = Brands;
  allFilters['Storeys'] = Storeys;
  allFilters['Colour'] = Colours;
  allFilters['HomeType'] = HomeTypes;
  allFilters['Categories'] = Categories;
  allFilters['Finishes'] = Finishes;

  // sort amenity
  //Rooms.sort()
  allFilters['Room'] = Rooms;
  allFilters['Designer'] = Designers;
  const globalConfig = allAttributes.silverStripeDataObject.UndigitalGlobalConfig
  const listFilters = data.list.map((filter, key) => 
    <StyleFilter isMobile={data.isMobile} key={`filter${key}`} type={filter} all={allFilters} globalConfig={globalConfig} activeFilters={data.activeFilters} onSelectItem={data.onSelectItem} onRenderItem={data.onRenderItem} />
  );
  return (
    <>
      {listFilters}
    </>
  )
}

export default RenderHomeType